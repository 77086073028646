import config from '../trove.config.json'

// if (process.env.NODE_ENV === 'development' || window.location.hostname === 'treasure.hunt') {
//     config.root    = 'https://treasure.hunt/';
//     config.ApiRoot = 'https://treasure.hunt/'
// }
if (process.env.NODE_ENV === 'development' || window.location.hostname === 'test.letsplaytrove.com') {
    config.root    = 'https://test.letsplaytrove.com/';
    config.ApiRoot = 'https://api-test.letsplaytrove.com/'
}
if (window.location.hostname === 'dev.letsplaytrove.com') {
    config.root    = 'https://dev.letsplaytrove.com/';
    config.ApiRoot = 'https://api-dev.letsplaytrove.com/'
}


config.ApiHunts         = config.ApiRoot + config.ApiBase + config.ApiHunts;
config.ApiHuntsByArea   = config.ApiRoot + config.ApiBase + config.ApiHuntsByArea;
config.ApiCategories    = config.ApiRoot + config.ApiBase + config.ApiCategories;
config.ApiPosts         = config.ApiRoot + config.ApiBase + config.ApiPosts;
config.ApiPages         = config.ApiRoot + config.ApiBase + config.ApiPages;
config.ApiNonce         = config.ApiRoot + config.ApiBase + config.ApiNonce;
config.ApiUserUpdate    = config.ApiRoot + config.ApiBase + config.ApiUserUpdate;
config.ApiLogin         = config.ApiRoot + config.ApiBase + config.ApiLogin;
config.ApiRegister      = config.ApiRoot + config.ApiBase + config.ApiRegister;
config.ApiReset         = config.ApiRoot + config.ApiBase + config.ApiReset;
config.ApiResetSend     = config.ApiRoot + config.ApiBase + config.ApiResetSend;
config.ApiVerifyReset   = config.ApiRoot + config.ApiBase + config.ApiVerifyReset;
config.ApiFeedback      = config.ApiRoot + config.ApiBase + config.ApiFeedback;
config.ApiReport        = config.ApiRoot + config.ApiBase + config.ApiReport;

export default config
